<template lang="pug">
#contact
    .contactbox
        .per-box 
            p 客服电话
            p {{ tel }}
        .per-box 
            p 联系邮箱
            p {{ email }}
</template>
 
<style src="../../stylus/reset.styl" lang="stylus"></style>
<script>
import { kefu } from "../../config/request";
import ShareImpl from '@/weixinApi.js';

export default {
    props: {},
    data() {
        return {
            tel: "",
            email: "",
        };
    },
    async created() {
        this.getDatail();
    },
    methods: {
        getDatail(val) {
            kefu().then((res) => {
                if (res.code === 20000) {
                    this.tel = res.data.tel;
                    this.email = res.data.email;
                    console.log(res,'detail11111')
                    let shareUrl = window.location.href;
                    // if(shareUrl.indexOf('from') != -1){
                    //   shareUrl = window.location.href.split("?")[0];
                    // }
                    // window.console.log(url + 'favicon.png');
                    console.log(res,11111);
                    let img = window.location.href.split("#")[0] + 'd.jpg';
                    let shareTitle = '链上汽车';
                    let shareDesc = res.data.share ? res.data.share.share_title : '联系客服';
                    console.log(shareDesc,'分享title')
                    let shareImg =  img;
                    // let shareImg = img;
                    console.log(shareImg,'图片111')
                    console.log(img,'本地111')
                    ShareImpl({
                      shareTitle,
                      shareDesc,
                      shareUrl: location.origin + location.pathname + "?share=true/" + location.hash,
                      shareImg
                    });
                }
            });
        },
    },
};
</script>
<style lang="stylus" scoped>
.contactbox {
    padding-top: 12px;

    .per-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 12px;
        background: #ffffff;
        border-bottom: 1px solid #F3F3F3;
        color: #666666;

        p {
            font-size: 16px;
            line-height: 26px;
        }
    }
}
</style>